import React, { useMemo } from 'react';
import { VictoryContainer, VictoryPie, VictoryTooltip, VictoryLegend, } from 'victory';
import { COLORS } from '@humanfirst/elektron';
import { PIE_CHART_THEME, sharedLabelStyles } from '../theme';
import { createDataEvents } from '../utils';
const DEFAULT_CHART_WIDTH = 640;
const DEFAULT_CHART_HEIGHT = 380;
const DEFAULT_RADIUS = 175;
const DEFAULT_INNER_RADIUS = 90;
const DEFAULT_LEGEND_X_OFFSET = 400;
const DEFAULT_LEGEND_LABEL_FONT_SIZE = 30;
const DEFAULT_LEGEND_SYMBOL_SPACER = 16;
const DEFAULT_COLOR_SCALE = [
    COLORS.primary,
    COLORS.blue4,
    COLORS.secondary,
    COLORS.blue3,
];
const DEFAULT_PADDING = {
    top: 0,
    bottom: 0,
    left: 0,
    right: 40,
};
const DEFAULT_DATA_EVENTS = createDataEvents();
const labelGetterFn = ({ datum }) => `${datum.x}: ${datum.y}`;
const PieChart = ({ data, donut = false, chartWidth = DEFAULT_CHART_WIDTH, chartHeight = DEFAULT_CHART_HEIGHT, radius = DEFAULT_RADIUS, innerRadius = DEFAULT_INNER_RADIUS, colorScale = DEFAULT_COLOR_SCALE, legendSymbolSpacer = DEFAULT_LEGEND_SYMBOL_SPACER, legendXOffset = DEFAULT_LEGEND_X_OFFSET, legendLabelFontSize = DEFAULT_LEGEND_LABEL_FONT_SIZE, padding = DEFAULT_PADDING, dataEvents = DEFAULT_DATA_EVENTS, }) => {
    const mappedLegendData = useMemo(() => {
        return data.map(({ x: name, y: count }) => ({
            name: `${name}: ${count}`,
            symbol: { type: 'square' },
        }));
    }, [data]);
    const legendHeight = useMemo(() => {
        const lineHeight = sharedLabelStyles.fontSize + 6;
        const numberOfItems = mappedLegendData.length;
        return numberOfItems * lineHeight;
    }, [mappedLegendData]);
    const legendYPosition = (chartHeight - legendHeight) / 2;
    return (React.createElement(VictoryContainer, { width: chartWidth, height: chartHeight, theme: PIE_CHART_THEME },
        React.createElement(VictoryPie, { standalone: false, radius: radius, innerRadius: donut ? innerRadius : 0, labelComponent: React.createElement(VictoryTooltip, null), labels: labelGetterFn, theme: PIE_CHART_THEME, padding: padding, data: data, events: dataEvents, colorScale: colorScale }),
        React.createElement(VictoryLegend, { colorScale: colorScale, style: {
                labels: Object.assign(Object.assign({}, sharedLabelStyles), { fontSize: legendLabelFontSize }),
            }, symbolSpacer: legendSymbolSpacer, standalone: false, data: mappedLegendData, y: legendYPosition, x: legendXOffset })));
};
export { PieChart };
