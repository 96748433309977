import { PATHS } from '../config/path';
import { generatePath } from './path';
import { deserializeQuery } from './query';
const SEARCH_RESOURCES = new Set([
    'MeasureSearch',
    'ToolSearch',
    'VendorSearch',
]);
const getResourceFromResourceType = (resourceType, isPlural = false) => {
    switch (resourceType) {
        case 'Tool':
            return isPlural ? 'technologies' : 'technology';
        case 'EvidenceSource':
            return 'evidence';
        case 'MedicalCondition':
            return isPlural ? 'medical conditions' : 'medical condition';
        case 'Study':
            return isPlural ? 'studies' : 'study';
        case 'Measure':
        case 'Vendor':
            return isPlural
                ? resourceType.toLowerCase() + 's'
                : resourceType.toLowerCase();
        case 'VendorSearch':
            return 'vendor search';
        case 'ToolSearch':
            return 'technology search';
        case 'MeasureSearch':
            return 'measure search';
        default:
            return 'item';
    }
};
const getResourceTypeFromWorkspaceSection = (section) => {
    switch (section) {
        case 'Technologies':
            return ['Tool'];
        case 'Evidence':
            return ['EvidenceSource'];
        case 'Medical Conditions':
            return ['MedicalCondition'];
        case 'Measures':
            return ['Measure'];
        case 'Vendors':
            return ['Vendor'];
        case 'Studies':
            return ['Study'];
        case 'Searches':
            return ['VendorSearch', 'MeasureSearch', 'ToolSearch'];
        default:
            return [];
    }
};
const getSearchResourceQueryParams = (resourceValue) => {
    const query = deserializeQuery(resourceValue);
    const queryParams = new URLSearchParams();
    if (query.search) {
        queryParams.set('query', query.search);
    }
    if (query.filters) {
        for (const filter of query.filters) {
            queryParams.append('filters', `${filter.fieldId}||${filter.filterType}||${JSON.stringify(filter.filterValue)}`);
        }
    }
    return queryParams.toString();
};
const getResourceUrl = (resourceType, resourceValue) => {
    switch (resourceType) {
        case 'Tool':
            return generatePath(PATHS.atlas.technologies.detail, {
                id: resourceValue,
            });
        case 'EvidenceSource':
            return generatePath(PATHS.atlas.evidence.detail, { id: resourceValue });
        case 'Measure':
            return generatePath(PATHS.atlas.measures.detail, { id: resourceValue });
        case 'MedicalCondition':
            return generatePath(PATHS.atlas.medicalConditions.detail, {
                id: resourceValue,
            });
        case 'Vendor':
            return generatePath(PATHS.atlas.vendors.detail, { id: resourceValue });
        case 'Study':
            return generatePath(PATHS.catalog.studies.view.index, {
                id: resourceValue,
            });
        case 'ToolSearch':
            return (generatePath(PATHS.atlas.technologies.list) +
                `?${getSearchResourceQueryParams(resourceValue)}`);
        case 'MeasureSearch':
            return (generatePath(PATHS.atlas.measures.list) +
                `?${getSearchResourceQueryParams(resourceValue)}`);
        case 'VendorSearch':
            return (generatePath(PATHS.atlas.vendors.list) +
                `?${getSearchResourceQueryParams(resourceValue)}`);
    }
    throw new Error(`unknown resource type: ${resourceType}`);
};
const getSection = (resourceType) => {
    const resource = getResourceFromResourceType(resourceType, true);
    return resource.charAt(0).toUpperCase() + resource.slice(1);
};
export { getResourceUrl, getSection, getResourceTypeFromWorkspaceSection, getResourceFromResourceType, SEARCH_RESOURCES, };
